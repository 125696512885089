/*************************
    Custom Styling
**************************/
.b2b-terms-of-service-title {
    font-size: x-large;
    margin-top: 1.5rem;
}

.b2b-terms-of-service-description {
    font-size: medium;
}

.b2b-terms-of-service-tos-entries {
    margin-bottom: 4rem;
}

.b2b-terms-of-service-button-group {
    display: table;
    margin-left: 0px;
    margin-right: auto;
    margin-top: 1.5rem;
}

.b2b-terms-of-service-button-primary {
    width: 140px;
}

.b2b-terms-of-service-button-secondary {
    width: 140px;
}
